import React from 'react';

const AnalysisCodes = React.lazy(() => import('../../views/Maintenance/AnalysisCodes/AnalysisCodes'));
const Calendars = React.lazy(() => import('../../views/Maintenance/Calendar/Calendar'));
const CategoryMaintenance = React.lazy(() => import('../../views/Maintenance/Categories/CategoryMaintenance'));
const DeliveryMaintenance = React.lazy(() => import('../../views/Maintenance/Deliveries/DeliveryMethodMaintenance'));
const DesignMaintenance = React.lazy(() => import('../../views/Maintenance/Designs/DesignMaintenance'));
const DivisionMaintenance = React.lazy(() => import('../../views/Maintenance/Divisions/DivisionMaintenance'));
const Grades = React.lazy(() => import('../../views/Maintenance/Grades/Grades'));
const Localization = React.lazy(() => import('../../views/Maintenance/Localization/Localization'));
const LocationMaintenance = React.lazy(() => import('../../views/Maintenance/Locations/LocationMaintenance'));
const LocationTypes = React.lazy(() => import('../../views/Maintenance/Locations/LocationTypeMaintenance'));
const Menus = React.lazy(() => import('../../views/Maintenance/Menus/Menus'));
const OrderAnalysis = React.lazy(() => import('../../views/Maintenance/OrderAnalysis/OrderAnalysis'))
const OrderTypeMaintenance = React.lazy(() => import('../../views/Maintenance/OrderTypes/OrderTypeMaintenance'))
const ReasonMaintenance = React.lazy(() => import('../../views/Maintenance/Reasons/ReasonMaintenance'));
const Roles = React.lazy(() => import('../../views/Maintenance/Roles/Roles'));
const SalesRepMaintenance = React.lazy(() => import('../../views/Maintenance/SalesReps/SalesRepMaintenance'));
const SystemKeys = React.lazy(() => import('../../views/Maintenance/SystemKeys/SystemKeys'));
const Users = React.lazy(() => import('../../views/Maintenance/Users/Users'));
const WarehouseMaintenance = React.lazy(() => import('../../views/Maintenance/Warehouses/WarehouseMaintenance'));
const StockMaintenance = React.lazy(() => import('../../views/Maintenance/Stock/StockMaintenance'));

const maintenanceRoutesProtected = [
  { path: '/maintenance/analysiscodes', exact: true, name: 'Analysis Codes', component: AnalysisCodes},
  { path: '/maintenance/calendars', exact: true, name: 'Calendars', component: Calendars},
  { path: '/maintenance/categorymaintenance', exact: true, name: 'Category Maintenance', component: CategoryMaintenance },
  { path: '/maintenance/deliverymethods', exact: true, name: 'Delivery Methods', component: DeliveryMaintenance },
  { path: '/maintenance/designmaintenance', exact: true, name: 'Design Maintenance', component: DesignMaintenance },
  { path: '/maintenance/divisionmaintenance', exact: true, name: 'Division Maintenance', component: DivisionMaintenance },
  { path: '/maintenance/grades', exact: true, name: 'Grades', component: Grades },
  { path: '/maintenance/localization', exact: true, name: 'Localization', component: Localization },
  { path: '/maintenance/locationmaintenance', exact: true, name: 'Location Maintenance', component: LocationMaintenance },
  { path: '/maintenance/locationtypemaintenance', exact: true, name: 'Location Type Maintenance', component: LocationTypes },
  { path: '/maintenance/menus', exact: true, name: 'Menus', component: Menus },
  { path: '/maintenance/orderanalysis', exact: true, name: 'Order Analysis', component: OrderAnalysis},
  { path: '/maintenance/ordertypemaintenance', exact: true, name: 'Order Type Maintenance', component: OrderTypeMaintenance },
  { path: '/maintenance/reasonmaintenance', exact: true, name: 'Reason Maintenance', component: ReasonMaintenance },
  { path: '/maintenance/roles', exact: true, name: 'Roles', component: Roles }, 
  { path: '/maintenance/salesrepmaintenance', exact: true, name: 'Sales Rep Maintenance', component: SalesRepMaintenance },
  { path: '/maintenance/system-keys', exact: true, name: 'System Keys', component: SystemKeys },
  { path: '/maintenance/users', exact: true, name: 'Users', component: Users },
  { path: '/maintenance/warehousemaintenance', exact: true, name: 'Warehouse Maintenance', component: WarehouseMaintenance },
  { path: '/maintenance/stockmaintenance', exact: true, name: 'Stock Maintenance', component: StockMaintenance },
];

export default maintenanceRoutesProtected;
